@import 'components/mixins.scss';

.cui {
  &__utils {
    &__content {
      padding: rem(30);
      margin: 0 auto;

      @media (max-width: $sm-max-width) {
        padding: rem(30) rem(16);
      }
    }

    &__heading {
      color: $black;
      font-size: rem(17);
      text-transform: uppercase;
      margin-bottom: rem(12);
    }

    &__shadow {
      box-shadow: $shadow-ant;
    }
  }
}

// dark theme
[data-kit-theme='dark'] {
  .cui {
    &__utils {
      &__heading {
        color: $dark-gray-1;
      }
    }
  }
}

.pageTitle {
  z-index: 3;
  font-size: rem(30);
  color: $black;
  font-weight: 600;
  line-height: 1.22;
  // text-decoration: underline;
  // text-decoration-color: $secondary;
  // text-decoration-thickness: 20px;
  // text-underline-offset: -18px;
  // background-color: $gray-2;
  margin-bottom: 30px;
  border-bottom: 2px $gray-3 solid;
}

.noScheduleSelected {
  text-align: center;
  width: 100%;
  font-size: 6vw;
  color: #ddd;
  p {
    animation: blinker 2s linear infinite;
    @keyframes blinker {
      50% {
        opacity: 0.5;
      }
    }
  }

  i {
    color: #f60;
    animation: updownAnimation 2s infinite !important;
    display: block;
    @keyframes updownAnimation {
      0% {
        transform: translateY(-60);
      }
      100% {
        transform: translateY(-70px);
      }
    }
  }
}
